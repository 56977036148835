import React from 'react'
// import CMS from 'netlify-cms'
// import BlogPostPreview from '../cms/preview-templates/BlogPostPreview'

// import '../../static/admin/config.yml'

const AdminPage = () => {

  return (
    <div>
        Hej från admin-page!

        {/* {CMS.registerPreviewTemplate('blog', BlogPostPreview)} */}


    </div>
  )

}

export default AdminPage